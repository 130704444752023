

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Menu: () => import('@/components/business/mobile/header/InsElMenu.vue'),
    InsLogin: () => import('@/components/business/pc/header/InsLogin.vue')
  }
})
export default class InsMenuLayout extends Vue {
  showMemNav: boolean = false;
  searchKey: string = '';

  handleOpen (key, keyPath) {
    console.log(key, keyPath);
  }
  handleClose (key, keyPath) {
    console.log(key, keyPath);
  }

  closeSlideMenu () {
    this.$store.dispatch('isShowMenu', false);
    document.body.style.position = 'relative';
    document.body.style.width = '100%';
  }

  private changLange (lang) {
    this.$Api.member.setUILanguage(lang === 'zh' ? 'C' : 'E').then((result) => {
      this.$i18n.locale = lang;
      // console.log(this.hhh, this.$t('test'),'test');
      localStorage.setItem('locale', lang);
      this.closeSlideMenu();
    }).catch((error) => {
      console.log(error);
    });
  }

  search () {
    // this.$store.dispatch('setSearchKey', this.searchKey);
    this.closeSlideMenu();
    this.$router.push({ path: `/product/search/${this.searchKey}` });
    this.searchKey = '';
  }

  changeLang (lang) {
    // this.$i18n.locale = lang;
    // localStorage.setItem('locale', lang);
    // window.location.reload();

    this.$Api.member.setUILanguage(lang).then((result) => {
      this.$i18n.locale = lang;
      // console.log(this.hhh, this.$t('test'),'test');
      localStorage.setItem('locale', lang);

      window.location.reload();
    }).catch((error) => {
      console.log(error);
    });
  }

  get user () {
    return this.$store.state.user;
  }

  get isLogin () {
    return this.$store.state.isLogin;
  }
}
